import styles from '@styles/footer.module.css'

function Footer() {
  return (
    <div className={styles.footer}>
      &copy; {new Date().getFullYear()} Old Row - All Rights Reserved.
    </div>
  )
}

export default Footer
