import styles from '@styles/socials.module.css'
import InstagramIcon from '@ui/icons/instagram'
import FacebookIcon from '@ui/icons/facebook'
import SnapchatIcon from '@ui/icons/snapchat'
import TiktokIcon from '@ui/icons/tiktok'
import TwitterIcon from '@ui/icons/twitter'

function Socials() {
  return (
    <div className={styles.socials}>
      <a href='https://www.tiktok.com/@oldrowofficial' target='_blank' rel='noreferrer'>
        <TiktokIcon />
      </a>
      <a
        href='https://snapchat.com/add/oldrowsports'
        target='_blank'
        rel='noreferrer'
        title='Snapchat'
      >
        <SnapchatIcon />
      </a>
      <a href='https://www.facebook.com/oldrow' target='_blank' rel='noreferrer' title='Facebook'>
        <FacebookIcon />
      </a>
      <a href='https://twitter.com/OldRowOfficial' target='_blank' rel='noreferrer' title='Twitter'>
        <TwitterIcon />
      </a>
      <a
        href='https://www.instagram.com/OldRowOfficial'
        target='_blank'
        rel='noreferrer'
        title='Instagram'
      >
        <InstagramIcon />
      </a>
    </div>
  )
}

export default Socials
