import styles from '@styles/header.module.css'
import Link from 'next/link'
import Logo from '@ui/icons/logo'
import Socials from '@ui/components/socials'

function Header() {
  return (
    <header className={styles.header}>
      <div className={styles.navigation}>
        <nav className={styles.nav_inner}>
          <Link href='/'>
            <a className={styles.logoLink}>
              <Logo className={styles.logo} />
            </a>
          </Link>
        </nav>
        <div className={styles.header_inner}>
          <div className={styles.header_cta}>
            <a className={styles.header_cta_link} href='https://submit.oldrow.net'>
              Submit Your Video
            </a>
          </div>
        </div>
        <div className={styles.subnav}>
          <Socials />
        </div>
      </div>
    </header>
  )
}

export default Header
