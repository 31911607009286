function TiktokIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10' cy='10' r='10' fill='currentColor' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0897 4V4.00957C12.0897 4.18205 12.139 6.67625 14.6652 6.83188C14.6652 9.15226 14.6663 6.83188 14.6663 8.75879C14.4764 8.77052 13.0032 8.66029 12.0864 7.81312L12.0835 11.5648C12.1063 13.2636 11.1934 14.9295 9.48439 15.2653C9.00559 15.3596 8.57388 15.3698 7.85068 15.206C3.68146 13.914 5.06712 7.50792 9.25312 8.19352C9.25312 10.2616 9.25429 8.19352 9.25429 10.2616C7.52433 9.99808 6.94641 11.4879 7.40596 12.5556C7.82414 13.5272 9.54617 13.7379 10.1467 12.367C10.2147 12.0986 10.2487 11.7928 10.2488 11.4495V4H12.0897Z'
        fill='white'
      />
    </svg>
  )
}

export default TiktokIcon
