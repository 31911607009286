import {
  formatDistanceToNowStrict,
  format,
  differenceInDays,
  isDate,
  parseISO,
} from 'date-fns'

export const formatDate = (date: string | Date, formatString = 'M/d h:mm a') => {
  try {
    let parsedDate
    if (isDate(date)) parsedDate = date as Date
    else parsedDate = parseISO(date as string)
    return format(parsedDate, formatString)
  } catch (err) {
    return 'Invalid Date'
  }
}

export const wordsOrDate = (date: string) => {
  if (differenceInDays(new Date(), new Date(date)) > 5) {
    return formatDate(date, 'M/d/yy h:mm a')
  }
  return `${formatDistanceToNowStrict(new Date(date))} ago`
}