import { flattenObject } from '@lib/utils'
import useSWR, { SWRResponse, SWRConfiguration, } from 'swr'
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite'
import { apiHost } from '@lib/env'
import type {
  Base,
  AnyKeys,
  HttpMethod,
  HttpBody,
  HttpParams,
  ListParams,
  ReadParams
} from './types'
import { ApiError } from './utils'

export class Service<Model extends Base> {
  basePath: string

  constructor(basePath: string) {
    this.basePath = basePath
  }

  async read(id: string, params?: ReadParams): Promise<Model> {
    return this.get(`${this.basePath}/${id}`, params ? flattenObject(params) : undefined)
  }

  async create(body: AnyKeys<Model>): Promise<Model> {
    return this.post(`${this.basePath}`, body)
  }

  async list(params?: ListParams<Model>): Promise<Model[]> {
    return this.get(`${this.basePath}`, params ? flattenObject(params) : undefined)
  }

  async readAndUpdate(id: string, body: AnyKeys<Model>): Promise<Model> {
    return this.put(`${this.basePath}/${id}`, body)
  }

  async readAndDelete(id: string, params?: HttpParams): Promise<Model> {
    return this.delete(`${this.basePath}/${id}`, params)
  }

  async get<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'get', params })
  }

  async delete<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'delete', params })
  }

  async post<T>(path: string, body?: HttpBody): Promise<T> {
    return this.request({ path, method: 'post', body })
  }

  async put<T>(path: string, body?: HttpBody): Promise<T> {
    return this.request({ path, method: 'put', body })
  }

  async patch<T>(path: string, body: HttpBody): Promise<T> {
    return this.request({ path, method: 'patch', body })
  }

  async request<T>(props: {
    path: string
    method: HttpMethod
    params?: HttpParams
    body?: HttpBody
  }): Promise<T> {
    // Build request
    const req = this.buildRequest(props)
    // Issue request
    const res = await fetch(`${apiHost}${req.path}`, {
      method: props.method.toUpperCase(),
      body: req.body,
      headers: {
        'content-type': 'application/json',
      }
    })
    // Parse json response
    const json = await res.json()

    // Throw response as an error if we did not receive a 200
    if (!res.ok) {
      throw new ApiError(res.status, json.message, json.error)
    }

    return json
  }

  private buildRequest(props: {
    path: string
    method: HttpMethod
    params?: HttpParams
    body?: HttpBody
  }): { path: string; body?: string } {
    let params = undefined
    let body = undefined

    switch (props.method) {
      case 'get':
      case 'delete':
      case 'options': {
        params = props.params
          ? Object.keys(props.params)
              .sort()
              .filter(
                (key: string) => props.params?.[key] !== undefined && props.params?.[key] !== null
              )
              .map((key: string) => [
                encodeURIComponent(key),
                encodeURIComponent(props.params?.[key] as string | number | boolean)
              ])
              .map(([key, value]) => `${key}=${value}`)
              .join('&')
          : ''
        break
      }
      case 'post':
      case 'put':
      case 'patch': {
        body = JSON.stringify(props.body ?? {})
        break
      }
      default:
        throw new Error('Invalid request method')
    }

    return {
      path: [props.path, params].filter(Boolean).join('?'),
      body: body,
    }
  }
}

export type { SWRConfiguration }

export function useRead<T extends Base>(
  service: Service<T>,
  id: string | null | undefined,
  params?: ReadParams,
  config?: SWRConfiguration
): SWRResponse<T, Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => {
    if (!id) return undefined
    return service.read(id as string, params)
  }
  return useSWR(
    id === undefined || id === null ? null : [`/${service.basePath}/${id}`, cacheKey],
    fetcher,
    config
  )
}

export function useList<T extends Base>(
  service: Service<T>,
  params?: ListParams<T>,
  config?: SWRConfiguration
): SWRResponse<T[], Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => service.list(params)
  return useSWR([`/${service.basePath}`, cacheKey], fetcher, config)
}

export function useInfiniteList<T extends Base>(
  service: Service<T>,
  params?: ListParams<T>,
  config?: SWRConfiguration
): SWRInfiniteResponse<T[], Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = (endpoint: string, key: string, pageIndex: number) => { 
    const paramsWithOffset = { ...params, offset: (params?.limit ?? 25) * pageIndex }
   return  service.list(paramsWithOffset)
  }
  return useSWRInfinite((pageIndex) => [`/${service.basePath}`, cacheKey, pageIndex], fetcher, config)
}

export function useGet<T>(
  service: Service<any>,
  path: string,
  params?: HttpParams,
  config?: SWRConfiguration
): SWRResponse<T, Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => service.get<T>(path, params)
  return useSWR([path, cacheKey], fetcher, config)
}

