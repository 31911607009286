export default function FacebookIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='#1877F2'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10' cy='10' r='10' fill='#1877F2' />
      <path
        d='M12.6068 10.75L12.9179 8.57828H10.9726V7.16898C10.9726 6.57484 11.2443 5.9957 12.1156 5.9957H13V4.14672C13 4.14672 12.1974 4 11.4301 4C9.82802 4 8.78083 5.04016 8.78083 6.92313V8.57828H7V10.75H8.78083V16H10.9726V10.75H12.6068Z'
        fill='white'
      />
    </svg>
  )
}
