import classNames from 'classnames'
import useClipboard from 'react-use-clipboard'
import styles from '@styles/post-actions.module.css'
import { isServer } from '@lib/utils'
import UpVote from '@ui/icons/upvote'
import Share from '@ui/icons/share'

interface IPostActions {
  vote: number
  handleVote: (vote: number) => void
  shareUrl: string
}

const navigator = isServer ? null : window.navigator

function PostActions({ vote, handleVote, shareUrl }: IPostActions) {
  const [isCopied, setCopied] = useClipboard(shareUrl, {
    successDuration: 1500
  })
  const handleShare = () => {
    if (navigator && navigator.share) {
      navigator.share({
        url: shareUrl
      })
    }
  }
  return (
    <section className={styles.post__actions}>
      <button
        className={classNames(styles.post__action, styles.post__action_upvote, {
          [styles.post__action_active]: vote === 1
        })}
        onClick={() => handleVote(1)}
      >
        <UpVote width='35px' height='35px' color={vote === 1 ? '#fff' : '#000'} />
      </button>
      {navigator && Object.hasOwn(navigator, 'share') ? (
        <button
          className={classNames(styles.post__action, styles.post__action_share)}
          onClick={handleShare}
        >
          <Share fill='#000' />
        </button>
      ) : (
        <button
          onClick={setCopied}
          data-tooltip={isCopied ? 'Link Copied' : 'Copy Link'}
          className={classNames(
            'tooltip tooltip-bottom',
            styles.post__action,
            styles.post__action_share,
            {
              [styles.post__action_active]: isCopied
            }
          )}
        >
          <Share fill='#000' />
        </button>
      )}
    </section>
  )
}

export default PostActions
