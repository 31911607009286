export function Share({ fill = '#FFF', width = '20px' }) {
  return (
    <svg viewBox='0 0 20 20' className='svgIcon iconShare' width={width}>
      <path
        d='M20 8.384L11.5314 0v5.5838C5.1628 5.5838 0 10.695 0 17c2.3396-4.2219 6.9814-6.3988 11.5314-5.8408v5.6088L20 8.384z'
        fill={fill}
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Share
