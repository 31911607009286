import React, { ReactNode } from 'react'
import Head from 'next/head'
import styles from '@styles/main-layout.module.css'
import Header from '@ui/components/header'
import Footer from '@ui/components/footer'

function MainLayout({
  children,
  title,
  description
}: {
  children: ReactNode
  minimal?: boolean
  title?: string
  description?: string
}) {
  return (
    <div className={styles.main_wrapper}>
      <Head>
        <title>{title ? `${title} | Old Row` : 'Old Row'}</title>
        <meta name='description' content={description || 'Old Row'} />
      </Head>
      <Header />
      <main className={styles.main}>
        <div className={styles.container}>{children}</div>
      </main>
      <Footer />
    </div>
  )
}

export default MainLayout
