import { useState, useEffect } from 'react'
import Loading from '@ui/icons/loading'
import styles from '@styles/loading-container.module.css'

interface ILoadingContainer {
  isLoading: boolean
  delay?: number
  children?: any
}

const LoadingContainer = ({ isLoading, delay = 0, children = null }: ILoadingContainer) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const timeout = isLoading && setTimeout(() => setVisible(true), delay)
    return () => {
      setVisible(false)
      timeout && clearTimeout(timeout)
    }
  }, [isLoading, delay])

  if (!isLoading) return children
  if (!visible) return null

  return (
    <>
      <div className={styles.loading_container}>
        <Loading />
      </div>
    </>
  )
}

export default LoadingContainer
