import { Service, useList, useRead, SWRConfiguration, useInfiniteList } from './common'
import { ListParams, Post, ReadParams } from '@api/types'

export type { Post }

export class ViralService extends Service<Post> {
  constructor() {
    super('/viral/posts')
  }

  async updateVoteById(id: string, data: any) {
    const result = await this.post(`${this.basePath}/${id}/vote`, data)
    return result
  }

  async updateViewedById(id: string) {
    const result = await this.post(`${this.basePath}/${id}/view`)
    return result
  }
}

export const viralService = new ViralService()

export function usePostsFeed(params?: ListParams<Post>, config?: SWRConfiguration) {
  const result = useInfiniteList(viralService, params, config)
  return { ...result, isLoading: !result.error && !result.data }
}

export function usePosts(params?: ListParams<Post>, config?: SWRConfiguration) {
  return useList(viralService, params, config)
}

export function usePost(id: string, params?: ReadParams, config?: SWRConfiguration) {
  return useRead(viralService, id, params, config)
}
