import { useRouter } from 'next/router'
import styles from '@styles/post-meta.module.css'
import { Post } from '@api/types'
import { abbreviateNumber } from '@lib/utils'

interface IPostMeta {
  post: Post
  views: Post['views'] | null
  votes: Post['votes'] | null
  date: string | null
}

function PostMeta({ post, votes, views, date }: IPostMeta) {
  const router = useRouter()

  if (!post || Array.isArray(post) || !post.votes) return null
  const abbreviatedViews = views ? abbreviateNumber(views.total) : null
  const abbreviatedLikes = votes && abbreviateNumber(votes.up)
  return (
    <section className={styles.post__meta_items}>
      <span className={styles.post__meta_item}>{abbreviatedLikes} likes</span>
      {abbreviatedViews && (
        <>
          <span className={styles.dotSeparator}>&#183;</span>
          <span className={styles.post__meta_item}>{abbreviatedViews} views</span>
        </>
      )}
      <div className={styles.post__date} onClick={() => router.push(`/posts/${post.id}`)}>
        {date}
      </div>
    </section>
  )
}

export default PostMeta
