function InstagramIcon() {
  return (
    <div>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='10' cy='10' r='10' fill='url(#paint0_radial_1242:1234)' />
        <path
          d='M10.0011 7.43613C8.58226 7.43613 7.43781 8.58083 7.43781 10C7.43781 11.4192 8.58226 12.5639 10.0011 12.5639C11.42 12.5639 12.5644 11.4192 12.5644 10C12.5644 8.58083 11.42 7.43613 10.0011 7.43613ZM10.0011 11.6669C9.08422 11.6669 8.33463 10.9193 8.33463 10C8.33463 9.08067 9.08199 8.33315 10.0011 8.33315C10.9202 8.33315 11.6676 9.08067 11.6676 10C11.6676 10.9193 10.918 11.6669 10.0011 11.6669ZM13.2671 7.33125C13.2671 7.66373 12.9994 7.92927 12.6693 7.92927C12.3369 7.92927 12.0714 7.6615 12.0714 7.33125C12.0714 7.001 12.3391 6.73324 12.6693 6.73324C12.9994 6.73324 13.2671 7.001 13.2671 7.33125ZM14.9649 7.93819C14.9269 7.13712 14.744 6.42754 14.1573 5.84291C13.5728 5.25828 12.8634 5.07531 12.0625 5.03514C11.237 4.98829 8.76297 4.98829 7.93753 5.03514C7.13887 5.07308 6.42945 5.25605 5.84272 5.84068C5.256 6.4253 5.07529 7.13489 5.03514 7.93596C4.98829 8.76158 4.98829 11.2362 5.03514 12.0618C5.07306 12.8629 5.256 13.5725 5.84272 14.1571C6.42945 14.7417 7.13664 14.9247 7.93753 14.9649C8.76297 15.0117 11.237 15.0117 12.0625 14.9649C12.8634 14.9269 13.5728 14.7439 14.1573 14.1571C14.7418 13.5725 14.9247 12.8629 14.9649 12.0618C15.0117 11.2362 15.0117 8.76381 14.9649 7.93819ZM13.8985 12.9477C13.7245 13.385 13.3876 13.722 12.9481 13.8982C12.29 14.1593 10.7284 14.0991 10.0011 14.0991C9.27384 14.0991 7.70998 14.1571 7.0541 13.8982C6.61684 13.7242 6.27998 13.3873 6.10374 12.9477C5.84272 12.2894 5.90296 10.7274 5.90296 10C5.90296 9.27256 5.84495 7.70836 6.10374 7.05233C6.27775 6.61497 6.61461 6.27803 7.0541 6.10175C7.71221 5.84068 9.27384 5.90093 10.0011 5.90093C10.7284 5.90093 12.2922 5.84291 12.9481 6.10175C13.3854 6.2758 13.7223 6.61274 13.8985 7.05233C14.1595 7.71059 14.0993 9.27256 14.0993 10C14.0993 10.7274 14.1595 12.2916 13.8985 12.9477Z'
          fill='white'
        />
        <defs>
          <radialGradient
            id='paint0_radial_1242:1234'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(2.5 20) rotate(-57.7244) scale(28.0903 25.9233)'
          >
            <stop stopColor='#F9ED32' />
            <stop offset='0.583333' stopColor='#EE2A7B' />
            <stop offset='1' stopColor='#002AFF' />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}

export default InstagramIcon
