import { v4 as uuid } from 'uuid'
import { isServer } from '@lib/utils'

const StorageKeys = Object.freeze({
  clientId: 'BS_CLIENT_ID',
  viralVotes: 'BS_VIRAL_VOTES',
})

class Storage {
  constructor() {
    this._generateClientId()
  }

  get clientId() {
    return this._get(StorageKeys.clientId)
  }

  get viralVotes() {
    return this._getJSON(StorageKeys.viralVotes) || {}
  }

  set viralVotes(newValue) {
    this._setJSON(StorageKeys.viralVotes, newValue)
  }

  _generateClientId() {
    if (this._get(StorageKeys.clientId)) {
      return
    }
    this._set(StorageKeys.clientId, uuid())
  }

  _getJSON(key: string) {
    try {
      const value = this._get(key)
      return value && JSON.parse(value)
    } catch (err) {
      return null
    }
  }

  _setJSON(key: string, value: any) {
    try {
      const json = JSON.stringify(value)
      return this._set(key, json)
    } catch (err) {
      return false
    }
  }

  _get(key: string) {
    if (isServer) {
      return null
    }
    return window.localStorage.getItem(key)
  }

  _set(key: string, newValue: any) {
    if (isServer) {
      return false
    }
    if (newValue) {
      return window.localStorage.setItem(key, newValue)
    } else {
      return window.localStorage.removeItem(key)
    }
  }
}

export default new Storage()
