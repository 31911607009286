export default function TwitterIcon(props: { className?: string }) {
  return (
    <div>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        className={props.className}
        fill='#1DA1F2'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='10' cy='10' r='10' fill='#1DA1F2' />
        <path
          d='M14.7665 7.49218C14.7741 7.60155 14.7741 7.71094 14.7741 7.82031C14.7741 11.1562 12.2995 15 7.77666 15C6.38325 15 5.08884 14.5859 4 13.8672C4.19798 13.8906 4.38831 13.8984 4.59391 13.8984C5.74364 13.8984 6.80203 13.5 7.64722 12.8203C6.56599 12.7969 5.6599 12.0703 5.34771 11.0703C5.50001 11.0937 5.65228 11.1094 5.81219 11.1094C6.033 11.1094 6.25383 11.0781 6.4594 11.0234C5.3325 10.789 4.48729 9.77343 4.48729 8.54687V8.51563C4.81469 8.70313 5.19543 8.82032 5.59896 8.83593C4.93652 8.38279 4.50253 7.60936 4.50253 6.73436C4.50253 6.26562 4.62433 5.83593 4.83754 5.46093C6.04821 6.99217 7.86802 7.99216 9.90862 8.10155C9.87055 7.91405 9.8477 7.71876 9.8477 7.52344C9.8477 6.1328 10.9442 5 12.3071 5C13.0152 5 13.6548 5.30469 14.1041 5.79687C14.6599 5.68751 15.1929 5.47656 15.665 5.1875C15.4822 5.77345 15.0939 6.26564 14.5838 6.57812C15.0787 6.52346 15.5584 6.3828 16 6.18751C15.665 6.68749 15.2462 7.13278 14.7665 7.49218Z'
          fill='white'
        />
      </svg>
    </div>
  )
}
