import isPlainObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'
import set from 'lodash/set'

export const isServer = typeof window === 'undefined'

export function flattenObject(
  _obj: Record<string, any>,
  _path: string[] = []
): Record<string, any> {
  const _flattenObject = (obj: Record<string, any>, path: string[]): Record<string, any> => {
    return !isPlainObject(obj)
      ? { [path.join('.')]: obj }
      : Object.entries(obj).reduce(
          (acc, [key, value]) => merge(acc, _flattenObject(value, [...path, key])),
          {}
        )
  }
  return _flattenObject(_obj, _path)
}

export function nestObject(_obj: Record<string, any>, _path: string[] = []): Record<string, any> {
  return Object.entries(_obj).reduce((acc, [key, value]) => set(acc, key, value), {})
}

export const abbreviateNumber = (number: number) => {
  const SI_SUFFIXES = ['', 'K', 'M', 'G', 'T', 'P', 'E']
  // what tier? (determines SI prefix)
  const tier = (Math.log10(number) / 3) | 0
  // if zero, we don't need a suffix
  if (tier === 0) return number.toString()
  // get suffix and determine scale
  const suffix = SI_SUFFIXES[tier]
  const scale = Math.pow(10, tier * 3)
  // scale the number
  const scaled = number / scale
  // format number and add prefix as suffix
  return scaled.toFixed(1).replace('.0', '') + suffix
}
