import React, { useState, useRef } from 'react'
import ReactPlayer from 'react-player'
import VisibilitySensor from 'react-visibility-sensor'
import styles from '@styles/player.module.css'
import { PlayerState } from '@ui/types/player'

interface IPlayer extends PlayerState {
  src: string
  thumbnail: string
  handleEndPlaying: () => void
  handleProgressEvent?: (event: any) => void
  ignoreVisibilityChanges?: boolean
}

function VideoPlayer(props: IPlayer) {
  const ref = useRef<ReactPlayer | null>(null)
  const player = ref.current
  const [muted, setMuted] = useState(false)
  const {
    src,
    thumbnail,
    playing,
    stopPlaying,
    togglePlaying,
    handleProgressEvent,
    ignoreVisibilityChanges = false
  } = props

  const handleVisibilityChange = (visible: boolean) => {
    if (!visible && playing) {
      stopPlaying()
    }
  }
  const muteButtonImgSrc = `/audio-${muted ? 'muted' : 'unmuted'}`
  return (
    <VisibilitySensor
      partialVisibility
      offset={{ top: -100, bottom: -100 }}
      onChange={handleVisibilityChange}
      active={!ignoreVisibilityChanges}
    >
      <div className={styles.playerWrapper}>
        <ReactPlayer
          ref={ref}
          url={src}
          className={styles.player}
          width='100%'
          height='100%'
          muted={muted}
          playing={playing}
          light={thumbnail}
          playsinline
          onClick={togglePlaying}
          onProgress={(progress) => {
            typeof handleProgressEvent === 'function' && handleProgressEvent(progress)
          }}
          onEnded={() => {
            player?.seekTo(0)
            props?.handleEndPlaying()
          }}
        />
        <div className={styles.muteContainer} onClick={() => setMuted((prevMuted) => !prevMuted)}>
          <img src={`${muteButtonImgSrc}@2x.png`} alt='' />
        </div>
      </div>
    </VisibilitySensor>
  )
}

export default VideoPlayer
