import { useRouter } from 'next/router'
import { Post } from '@api/types'

function PostOpenGraph({ post }: { post: Post | undefined }) {
  const router = useRouter()
  const pathname = router?.asPath.split('?')[0]
  const url = `${process.env.NEXT_PUBLIC_SITE_URL}${pathname}`
  const mediaType = post?.media?.type ?? ''
  const thumbnail = post?.media?.thumbnail ?? ''
  const attribution = post?.attribution
  const postUrl = `${process.env.NEXT_PUBLIC_SITE_URL}/posts/${post?.id}`
  const attributionLabel = attribution?.twitter || attribution?.instagram || 'OldRowOfficial'
  const message = post?.message ?? ''
  return (
    <>
      <title>Old Row Viral | Old Row</title>
      <link rel='canonical' href={url} />
      {mediaType === 'video' && <meta property='og:video' content={post?.media.src} />}
      <meta property='og:title' content={`Viral Post by ${attributionLabel}`} />
      {mediaType === 'video' ? (
        <meta property='og:type' content='video.other' />
      ) : (
        <meta property='og:type' content='article' />
      )}
      <meta property='og:image' content={thumbnail} />
      {mediaType === 'image' && <meta property='og:image' content={post?.media.src} />}
      <meta property='og:url' content={postUrl} />
      <meta property='og:description' content={message} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@OldRowOfficial' />
      <meta name='twitter:creator' content='@OldRowOfficial' />
      <meta name='twitter:title' content={`Viral Post by ${attributionLabel}`} />
      <meta name='twitter:description' content={message} />
      <meta name='twitter:image' content={thumbnail} />
      <meta name='twitter:image:alt' content={message} />
    </>
  )
}

export default PostOpenGraph
