import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import styles from '@styles/post.module.css'
import { Post } from '@api/types'
import { wordsOrDate } from '@lib/time'
import Player from '@ui/components/video-player'
import { PlayerState } from '@ui/types/player'
import PostActions from '@ui/components/post-actions'
import PostMeta from '@ui/components/post-meta'
import storage from '@lib/storage'
import { viralService } from '@api/viral'
import { useMatchMutate } from '@ui/hooks/match-mutate'

interface IPost extends PlayerState {
  post: Post
  ignoreVisibilityChanges?: boolean
}

const setLocalStorageVotes = (id: string, val: number) => {
  const currentVotes = storage.viralVotes
  storage.viralVotes = { ...currentVotes, [id]: val }
}

function Post(props: IPost) {
  const matchMutate = useMatchMutate()
  const [viewed, setViewed] = useState(false)
  const [currentVote, setCurrentVote] = useState(0)
  const latestViewed = useRef(false)

  useEffect(() => {
    // set current vote per post
    setCurrentVote(storage.viralVotes[post.id] ?? 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // set latest viewed
    latestViewed.current = viewed
  })

  const handleVote = async (next: number) => {
    const allPrevious = storage.viralVotes
    const previous = allPrevious[post.id] ?? 0

    if (next === previous) {
      // like decrement
      await viralService.updateVoteById(post.id, { vote: 0, previous_vote: previous })
      setLocalStorageVotes(post.id, 0)
    } else {
      // like increment
      await viralService.updateVoteById(post.id, { vote: next, previous_vote: previous })
      setLocalStorageVotes(post.id, next)
    }
    await matchMutate(/\/viral/)
    setCurrentVote(next)
  }

  const handleProgressEvent = (progressEvent: any) => {
    if (!latestViewed.current) {
      // update views for video if more than 3 seconds have played OR if video has played
      // more than 50%, second condition needed for short videos which might never reach 3 seconds of playing
      if (progressEvent.playedSeconds > 3 || progressEvent.played > 0.5) {
        viralService.updateViewedById(post.id)
        setViewed(true)
      }
    }
  }

  const handleEnd = () => {
    setViewed(false)
  }

  const { post, ignoreVisibilityChanges } = props
  const date = post.date ? wordsOrDate(post.date) : null
  const mediaType = post.media?.type ?? null
  const thumbnail = post.media?.thumbnail ?? null
  const postUrl = `${process.env.NEXT_PUBLIC_SITE_URL}/posts/${post.id}`

  return (
    <article className={styles.post}>
      <section className={styles.post__content}>
        {mediaType === 'video' ? (
          <div className={classNames(styles.postContent__media, styles.postContent__media_video)}>
            <Player
              {...props}
              src={post.media.src}
              thumbnail={thumbnail}
              handleEndPlaying={handleEnd}
              handleProgressEvent={handleProgressEvent}
              ignoreVisibilityChanges={ignoreVisibilityChanges}
            />
          </div>
        ) : (
          <div className={styles.playerWrapper}>
            <div
              className={classNames(styles.postContent__media, styles.postContent__media_image)}
              style={{
                backgroundImage: `url(${thumbnail})`,
                position: 'absolute',
                left: 0,
                top: 0
              }}
            />
          </div>
        )}
        <footer className={styles.post__footer}>
          <PostActions vote={currentVote} handleVote={handleVote} shareUrl={postUrl} />
          {post.message && <p className={styles.post__description}>{post.message}</p>}
          <PostMeta
            date={date}
            views={mediaType === 'video' ? post.views : null}
            votes={{ ...post.votes, up: post.votes.up + currentVote }}
            post={post}
          />
        </footer>
      </section>
    </article>
  )
}

export default Post
